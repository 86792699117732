import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import { insertOurMission, updateOurMission } from "../../../api/api";
import CKEditors from "react-ckeditor-component";

const AntsMission = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    setText(newContent);
  };

  const handleAdd = async () => {
    const formData = { mission_title: title, mission_text: text };

    const res = await insertOurMission(formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/mission/mission_list`);
    } else {
      alert("Error");
    }
  };
  const handleUpdate = async (id) => {
    const formData = { mission_title: title, mission_text: text };

    const res = await updateOurMission(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/mission/mission_list`);
    } else {
      alert("Error occured.");
    }
  };

  const populateData = () => {
    if (location?.state) {
      setTitle(location?.state?.dataObj?.mission_title);
      setText(location?.state?.dataObj?.mission_text);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  return (
    <>
      <Fragment>
        <Breadcrumbs
          parent="Our Mission"
          title="Add Our Mission"
          mainTitle="Add Our Mission"
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form method="post">
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Title"}</Label>
                          <input
                            placeholder="Title"
                            className="form-control"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Short Text"}</Label>
                          <CKEditors
                            activeclassName="p10"
                            content={text}
                            events={{
                              change: onChange,
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          {location?.state ? (
                            <Button
                              onClick={() =>
                                handleUpdate(
                                  location?.state?.dataObj?.mission_id
                                )
                              }
                              className="me-3"
                              color="success"
                            >
                              {"Update"}
                            </Button>
                          ) : (
                            <Button
                              onClick={handleAdd}
                              className="me-3"
                              color="success"
                            >
                              {"Submit"}
                            </Button>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default AntsMission;
