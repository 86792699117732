import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, Image } from "../../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { deleteAntsProducts, getAntsProducts, serverImageUrl } from "../../../api/api";

export const style = {
  width: 40,
  height: 40,
};
export const style2 = { width: 60, fontSize: 14, padding: 4 };

function ProductLists() {
  const navigate = useNavigate();
  const [dataAPI, setDataAPI] = useState([]);

  const populateData = async () => {
    const res = await getAntsProducts();
    setDataAPI([...res.data]);
  };

  // console.log(`dataAPI`, dataAPI);
  const deleteDataItem = async (id) => {
    const res = await deleteAntsProducts(id);
    if (res.status === 200) {
      const freshArray = dataAPI.filter((val) => val.products_id !== id);
      setDataAPI(freshArray);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  const addDataAPI = dataAPI?.map((elem) => {
    return {
      Image: (
        <Image
          attrImage={{
            src: serverImageUrl + elem.products_featuredImg,
            style: style,
            alt: "Unavailable",
          }}
        />
      ),
      Title: elem.products_title,
      action: (
        <div>
          <span>
            <Button
              onClick={() => deleteDataItem(elem.products_id)}
              className="btn btn-danger btn-xs"
              style={style2}
            >
              Delete
            </Button>
          </span>
          &nbsp;&nbsp;
          <span>
            <Button
              onClick={() => {
                navigate(
                  `${process.env.PUBLIC_URL}/product/add_products`,
                  {
                    state: { dataObj: elem },
                  }
                );
              }}
              className="btn btn-success btn-xs"
              style={style2}
            >
              Edit
            </Button>
          </span>
        </div>
      ),
    };
  });

  const addColumns = [
    {
      name: "Image",
      selector: (row) => row.Image,
      sortable: true,
      center: true,
    },
    {
      name: "Title",
      selector: (row) => row.Title,
      sortable: true,
      center: true,
    },
    {
      name: "Text",
      selector: (row) => row.Text,
      sortable: true,
      center: true,
    },
    {
      name: "Description",
      selector: (row) => row.Description,
      sortable: true,
      center: true,
    },
    {
      name: "Url",
      selector: (row) => row.Url,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs
        parent="Products"
        title="Products List"
        mainTitle="Products List"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="table-responsive product-table">
                  <DataTable
                    noHeader
                    pagination
                    paginationServer
                    columns={addColumns}
                    data={addDataAPI}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default ProductLists;
