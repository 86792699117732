import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs } from "../../AbstractElements";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import CKEditors from "react-ckeditor-component";
import { getDonationSection,updateDonationSection } from "../../api/api";
export default function DonationSection() {
    const [title, settitle] = useState('')
    const [desc, setdesc] = useState('')
    const [id, setid] = useState()
    console.log(id, 'checker')

    const onChange = (evt) => {
        const newContent = evt.editor.getData();
        setdesc(newContent);
    };

    const handleUpdate = async() => {
        if (!title) {
            return alert("Please Enter the Title")
        }
        if (!desc) {
            return alert("Please Enter the Description")
        }

        const formData = { title, description: desc }

        const res = await updateDonationSection(formData, id);
        if (res.status === 200) {
            console.log('Update Successfully')
            populateData();

        } else {
            alert("Error");
        }
    }


    const populateData = async () => {
        const res = await getDonationSection();
        settitle(res.data[0].title)
        setdesc(res.data[0].description)
        setid(res.data[0].id)

    };

    useEffect(() => {
        populateData();
    }, []);
    return (
        <Fragment>
            <Breadcrumbs
                parent="Donation Section"
                title="Add Donation Section"
                mainTitle="Add Donation Section"
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Form method="POST">
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label>{"Title"}</Label>
                                                <Input
                                                    onChange={(e) => settitle(e.target.value)}
                                                    value={title}
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    placeholder="Enter The Title of Personal Donation"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label>{"Description"}</Label>
                                                <CKEditors
                                                    activeclassName="p10"
                                                    content={desc}
                                                    events={{
                                                        change: onChange,
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="mb-0">
                                                <Button
                                                    className="me-3"
                                                    color="success"
                                                    onClick={handleUpdate}
                                                >
                                                    {"Update"}
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
