import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs } from "../../AbstractElements";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import { getProjectType, insertProjectCate, insertProjectType, updateProjectCate, updateProjectType } from "../../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import Select from 'react-select';

const AddProjectCate = () => {
    const [data, setdata] = useState([])

    const [cateName, setcateName] = useState('')
    const [typeName, settypeName] = useState('')
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false)

    const handleAdd = async () => {
        const formData = {
            cate_name: cateName,
            project_type: typeName.value,
        }
        setIsLoading(true)
        const res = await insertProjectCate(formData);
        if (res.status === 200) {
            setIsLoading(false)
            navigate(`${process.env.PUBLIC_URL}/project/categoryList`);
        } else {
            setIsLoading(false)
            alert("Error");
        }
    };
    const handleUpdate = async (id) => {
        const formData = {
            cate_name: cateName,
            project_type: typeName.value,
        }
        setIsLoading(true)
        const res = await updateProjectCate(id, formData);
        if (res.status === 200) {
            setIsLoading(false)
            navigate(`${process.env.PUBLIC_URL}/project/categoryList`);
        } else {
            setIsLoading(false)
            alert("Error occured.");
        }
    };

    const populateData = () => {
        if (location?.state) {
            setcateName(location?.state?.dataObj?.cate_name);
        }
    };

    const populateData2 = async () => {
        const res = await getProjectType();
        const types = []
        res.data.forEach(element => {
            var value = element.type_name
            var label = element.type_name
            types.push({ value, label })
        });
        setdata(types)

        if (location?.state) {
            const defaultOption = types.find(option => option.value === location?.state?.dataObj?.project_type)
            settypeName(defaultOption)
        }
    };

    useEffect(() => {
        populateData();
        populateData2();
    }, []);

    return (
        <>
            <Fragment>
                <Breadcrumbs
                    parent="Projects"
                    title="Add Project Category"
                    mainTitle="Add Project Category"
                />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <Form method="POST">
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>{"Category Name"}</Label>
                                                    <Input
                                                        type="text"
                                                        required
                                                        value={cateName}
                                                        className="form-control"
                                                        placeholder="Enter Type Name"
                                                        onChange={(e) => setcateName(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>{"Select Type"}</Label>
                                                    <Select
                                                        options={data}
                                                        value={typeName}
                                                        onChange={e => settypeName(e)}
                                                        className="js-example-basic-single col-sm-12"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup className="mb-0">
                                                    {location?.state ? (
                                                        <Button
                                                            onClick={() =>
                                                                handleUpdate(location?.state?.dataObj?.cate_id)
                                                            }
                                                            className="me-3"
                                                            color="success"
                                                        >
                                                            {
                                                                isLoading ?
                                                                    <div class="spinner-border spinner-border-sm" role="status">
                                                                        <span class="sr-only">Loading...</span>
                                                                    </div>
                                                                    : "Update"
                                                            }
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            onClick={handleAdd}
                                                            className="me-3"
                                                            color="success"
                                                        >
                                                            {
                                                                isLoading ?
                                                                    <div class="spinner-border spinner-border-sm" role="status">
                                                                        <span class="sr-only">Loading...</span>
                                                                    </div>
                                                                    : "Submit"
                                                            }
                                                        </Button>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        </>
    );
};

export default AddProjectCate;
