import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs } from "../../AbstractElements";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import CKEditors from "react-ckeditor-component";
import { insertPersonalDonation } from "../../api/api";
import { useNavigate } from "react-router-dom";
export default function AddPersonalDonation() {
    const navigate = useNavigate();
    const [title, settitle] = useState('')
    const [walletAddress, setwalletAddress] = useState('')

    const handleAdd = async() => {
        if (!title) {
            return alert("Please Enter the Title")
        }
        if (!walletAddress) {
            return alert("Please Enter the Wallet Address")
        }

        const formData = { title,walletAddress }

        const res = await insertPersonalDonation(formData);
        if (res.status === 200) {
            console.log('Added Successfully')
            navigate("/app/listPersonalDonation")
        } else {
            alert("Error");
        }
    }
    return (
        <Fragment>
            <Breadcrumbs
                parent="Donation Section"
                title="Add Personal Donation"
                mainTitle="Add Personal Donation"
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Form method="POST">
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label>{"Title"}</Label>
                                                <Input
                                                    onChange={(e) => settitle(e.target.value)}
                                                    value={title}
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    placeholder="Enter The Title of Personal Donation"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label>{"Wallet Address"}</Label>
                                                <Input
                                                    onChange={(e) => setwalletAddress(e.target.value)}
                                                    value={walletAddress}
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    placeholder="Enter Your Wallet Address"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="mb-0">
                                                <Button
                                                    className="me-3"
                                                    color="success"
                                                    onClick={handleAdd}
                                                >
                                                    {"Add"}
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
