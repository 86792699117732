import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import { insertRoadmap, updateRoadmap } from "../../../api/api";
import Dropzone from "react-dropzone-uploader";

const AntsRoadmap = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [imageFile, setImageFile] = useState('')

  const navigate = useNavigate();
  const location = useLocation();

  const handleChangeStatus = ({ meta, file }, status) => {
    setImageFile(file);
  };

  const handleAdd = async () => {
    // const formData = { howworks_title: title, howworks_text: text };

    const formData = new FormData();
    formData.append("howworks_title", title);
    formData.append("howworks_text", text);
    formData.append("iconImage", imageFile);

    const res = await insertRoadmap(formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/works/howworks_list`);
    } else {
      alert("Error");
    }
  };
  const handleUpdate = async (id) => {
    // const formData = { howworks_title: title, howworks_text: text };

    const formData = new FormData();
    formData.append("howworks_title", title);
    formData.append("howworks_text", text);
    formData.append("iconImage", imageFile);

    const res = await updateRoadmap(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/works/howworks_list`);
    } else {
      alert("Error occured.");
    }
  };

  const populateData = () => {
    if (location?.state) {
      setTitle(location?.state?.dataObj?.howworks_title);
      setText(location?.state?.dataObj?.howworks_text);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  return (
    <>
      <Fragment>
        <Breadcrumbs
          parent="How Works"
          title="Add How Works"
          mainTitle="Add How Works"
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form method="post">
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Title"}</Label>
                          <input
                            placeholder="Title"
                            className="form-control"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Short Text"}</Label>
                          <input
                            placeholder="Text"
                            className="form-control"
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Image/Icon"}</Label>
                          <Dropzone
                            onChangeStatus={handleChangeStatus}
                            inputContent="Drop An Image"
                            multiple={false}
                            canCancel={true}
                            maxFiles={1}
                            styles={{
                              dropzone: { width: "100%", minHeight: 50 },
                              dropzoneActive: { borderColor: "green" },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          {location?.state ? (
                            <Button
                              onClick={() =>
                                handleUpdate(
                                  location?.state?.dataObj?.howworks_id
                                )
                              }
                              className="me-3"
                              color="success"
                            >
                              {"Update"}
                            </Button>
                          ) : (
                            <Button
                              onClick={handleAdd}
                              className="me-3"
                              color="success"
                            >
                              {"Submit"}
                            </Button>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default AntsRoadmap;
