import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import {
  getProjectCategory,
  insertCreatePlans,
  updateCreatePlans,
} from "../../../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import CKEditors from "react-ckeditor-component";

const CreatePlans = () => {
  const [name, setName] = useState("");
  const [membership, setMemberhsip] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [totalvote, setTotalVote] = useState("");
  const [numOfVotes, setNumOfVotes] = useState([]);
  const [getCategories, setCategories] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    setDescription(newContent);
  };


  const handlePlans = async (e) => {
    e.preventDefault();
    const sumOfVotes = numOfVotes.reduce(
      (acc, value) => acc + Number(value.numOfVotes),
      0
    );
    if (totalvote !== sumOfVotes.toString()) {
      alert("The total number of votes does not match the to allowed votes.");
      return;
    }

    const formData = {
      plan_name: name,
      plan_membership: membership,
      plan_description: description,
      plan_amount: amount,
      plan_totalvotes: totalvote,
      plan_numofvotes: JSON.stringify(numOfVotes),
    };
    const res = await insertCreatePlans(formData);
    if (res?.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/plans/plans_list`);
    } else {
      alert("Error occured.");
    }
  };

  const handleUpdate = async (id) => {
    const sumOfVotes = numOfVotes.reduce(
      (acc, value) => acc + Number(value.numOfVotes),
      0
    );

    if (totalvote.toString() !== sumOfVotes.toString()) {
      alert("The total number of votes does not match the to allowed votes.");
      return;
    }

    const formData = {
      plan_name: name,
      plan_membership: membership,
      plan_description: description,
      plan_amount: amount,
      plan_totalvotes: totalvote,
      plan_numofvotes: JSON.stringify(numOfVotes),
    };

    const res = await updateCreatePlans(id, formData);
    if (res?.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/plans/plans_list`);
    } else {
      alert("Error occured.");
    }
  };

  const fetchCategories = async () => {
    const req = await getProjectCategory();
    setCategories(req.data);
  };

  const populateData = () => {
    if (location?.state) {
      setName(location?.state?.dataObj?.plan_name);
      setMemberhsip(location?.state?.dataObj?.plan_membership);
      setDescription(location?.state?.dataObj?.plan_description);
      setAmount(location?.state?.dataObj?.plan_amount);
      setTotalVote(location?.state?.dataObj?.plan_totalvotes);
      const parsedNumOfVotes = JSON.parse(
        location?.state?.dataObj?.plan_numofvotes
      );
      const updatedNumOfVotes = parsedNumOfVotes.map(
        ({ category_id, category_name, numOfVotes }) => ({
          category_id,
          category_name,
          numOfVotes,
        })
      );
      setNumOfVotes(updatedNumOfVotes);
    }
  };

  useEffect(() => {
    fetchCategories();
    populateData();
  }, []);

  const handleNumOfVotesChange = (index, value) => {
    const updatedNumOfVotes = [...numOfVotes];
    updatedNumOfVotes[index] = {
      ...updatedNumOfVotes[index],
      numOfVotes: value,
    };
    setNumOfVotes(updatedNumOfVotes);
  };

  return (
    <Fragment>
      <Breadcrumbs
        parent="Ants Wallet"
        title="Add Ants Wallet"
        mainTitle="Add Ants Wallet"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form method="post">
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Name"}</Label>
                        <Input
                          type="text"
                          placeholder="Enter Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Membership Type"}</Label>
                        <Input
                          type="text"
                          placeholder="Enter Membership"
                          value={membership}
                          onChange={(e) => setMemberhsip(e.target.value)}
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Crietria(Total Votes Allow)*"}</Label>
                        <Input
                          type="number"
                          placeholder="Enter Votes"
                          value={totalvote}
                          onChange={(e) => setTotalVote(e.target.value)}
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Amount(Per Vote)*"}</Label>
                        <Input
                          type="number"
                          placeholder="Enter Amount"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Description</Label>
                        <CKEditors
                          activeclassName="p10"
                          content={description}
                          events={{
                            change: onChange,
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div>
                    <Label>Categories List</Label>
                    {getCategories?.map((c, i) => (
                      <Row key={i}>
                        <Col lg={6}>
                          <FormGroup>
                            <Input
                              type="text"
                              placeholder={c.category_name}
                              readOnly
                              value={c.category_name}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6}>
                          <FormGroup>
                            <Input
                              type="number"
                              className="form-control"
                              value={
                                numOfVotes[i] !== null &&
                                numOfVotes[i] !== undefined
                                  ? numOfVotes[i].numOfVotes
                                  : ""
                              }
                              onChange={(e) =>
                                handleNumOfVotesChange(i, e.target.value)
                              }
                              placeholder="Enter Number Votes Allowed"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    ))}
                  </div>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0">
                        {location?.state ? (
                          <Button
                            onClick={() =>
                              handleUpdate(location?.state?.dataObj?.plans_id)
                            }
                            className="me-3"
                            color="success"
                          >
                            {"Update"}
                          </Button>
                        ) : (
                          <Button
                            onClick={handlePlans}
                            className="me-3"
                            color="success"
                          >
                            {"Submit"}
                          </Button>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CreatePlans;
