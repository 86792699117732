import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5 } from "../../../AbstractElements";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { getBadges, getUsersAndBadges, onUpdateUserBadges } from "../../../api/api";
import toast from 'react-hot-toast';

const AntsUsers = () => {
  const [dataAPI, setDataAPI] = useState([]);
  const [getBadgeList, setGetBadgeList] = useState([]);

  const fetchBadges = async () => {
    const res = await getBadges();
    setGetBadgeList(res.data);
  };

  const fetchApiData = async () => {
    const res = await getUsersAndBadges();
    setDataAPI([...res.data]);
  };

  const handleBadges = async (userId, selectedBadgeId, name) => {
    const formData = {
      user_badge_id: selectedBadgeId,
    };
    const res = await onUpdateUserBadges(userId, formData);
    if (res.status === 200) {
      fetchApiData();
      toast.success(`${name} Badge Selected`);
    }
  };

  const addDataAPI = dataAPI?.map((elem) => {
    return {
      ProfileImg: elem.user_profileimg,
      Name: elem.user_username,
      Email: elem.user_email,
      Social: elem.user_sociallink,
      Wallet: elem.user_walletaddress,
      ShowBadge: elem.badge_name,
      action: (
        <div>
          <FormGroup>
            <Input
              type="select"
              className="form-control"
              onChange={(e) => {
                const selectedBadgeId = e.target.value;
                handleBadges(elem.user_id, selectedBadgeId, elem.badge_name);
              }}
            >
              <option>Select a Badge</option>
              {getBadgeList?.map((b, i) => (
                <option key={i} value={b?.badge_id}>
                  {b?.badge_name}
                </option>
              ))}
            </Input>
          </FormGroup>
        </div>
      ),
    };
  });
  const addColumns = [
    {
      name: "Profile",
      selector: (row) => row.ProfileImg,
      sortable: true,
      center: true,
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
      center: true,
    },
    {
      name: "Email",
      selector: (row) => row.Email,
      sortable: true,
      center: true,
    },
    {
      name: "Social",
      selector: (row) => row.Social,
      sortable: true,
      center: true,
    },
    {
      name: "Wallet",
      selector: (row) => row.Wallet,
      sortable: true,
      center: true,
    },
    {
      name: "Badge",
      selector: (row) => row.ShowBadge,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      center: true,
    },
  ];

  useEffect(() => {
    fetchApiData();
    fetchBadges();
  }, []);

  return (
    <Fragment>
      <Breadcrumbs parent="Users" title="Users List" mainTitle="Users List" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>{"Users List"}</H5>
              </CardHeader>
              <CardBody>
                <div className="table-responsive product-table">
                  <DataTable
                    noHeader
                    pagination
                    paginationServer
                    columns={addColumns}
                    data={addDataAPI}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AntsUsers;
