import React,{Fragment,useState,useEffect} from 'react'
import { Breadcrumbs } from '../../../AbstractElements'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { updateHowToVote, getHowToVote } from '../../../api/api';
export default function HowToVote() {
  const navigate = useNavigate();
  
  const [id, setid] = useState()
  const [link, setlink] = useState('')

  const handleUpdate = async () => {
    if(!link){
      return alert("Please Paste the Youtube Link")
    }
    const formData = {link}

    const res = await updateHowToVote(formData,id);
    if (res.status === 200) {
      alert('Update Successfully')
    } else {
      alert("Error");
    }
  };

const populateData = async () => {
    const res = await getHowToVote();
    setlink(res.data[0].htv_VideoLink)
    setid(res.data[0].htv_id)
  };


  useEffect(() => {
    populateData();
  }, []);
  return (
    <Fragment>
    <Breadcrumbs parent="How To Vote" title="How To Vote" mainTitle="How To Vote" />
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Form method="POST">
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>{"Youtube Video Link"}</Label>
                      <Input
                        onChange={(e) => setlink(e.target.value)}
                        value={link}
                        type="text"
                        className="form-control"
                        required
                        placeholder="Paste Youtube Link"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                    <Col>
                      <FormGroup className="mb-0">
                          <Button
                            onClick={handleUpdate}
                            className="me-3"
                            color="success"
                          >
                            {"Update"}
                          </Button>
                      </FormGroup>
                    </Col>
                  </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </Fragment>
  )
}
