import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5 } from "../../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getCashbackUsers, updateCashbackList, getWalletAssocaite } from "../../../api/api";
import ABI from "../../../api/abi";
import Web3 from "web3";
import axios from "axios";

export const style = {
  width: 40,
  height: 40,
};
export const style2 = { width: 60, fontSize: 14, padding: 4 };

function CashbacksList() {
  const navigate = useNavigate();
  const [dataAPI, setDataAPI] = useState([]);
  const [etherPrice, setEtherPrice] = useState(0);

  const populateData = async () => {
    const res = await getCashbackUsers();
    const filteredData = res.data.filter((item) => item.cashback_list_status != "Pending")
    setDataAPI(filteredData);
  };

  function getDollarValues() {
    axios
      .get(
        `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2Cethereum&order=market_cap_desc  &per_page=100&page=1&sparkline=false`
      )
      .then((response) => {
        // console.log(response);
        // console.log(response.data[1].current_price);
        setEtherPrice(response.data[1].current_price);
        // setDollarMintAmount(dollarAmount);
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    populateData();
    getDollarValues();
  }, []);
  const ApproveCashback = async (cashback_list_id, recipient, amount) => {
    console.log("Amount: ", amount)
    const res = await getWalletAssocaite();
    console.log(res.data)
    let associateCashbackPercentage = res.data[0].wallet_percentage
    let associateCashbackWalletAddress = res.data[0].wallet_address
    const tokenPrice = 0.01;
    let totalTokens = amount / tokenPrice
    console.log("totalTokens", totalTokens)
    totalTokens = Math.round(totalTokens)
    let associateTokens = (associateCashbackPercentage / 100.0) * totalTokens
    console.log("associateTokens", associateTokens)
    associateTokens = Math.round(associateTokens)
    let tokensForRewards = totalTokens - associateTokens
    console.log("associateCashbackPercentage", associateCashbackPercentage)
    console.log("associateCashbackWalletAddress", associateCashbackWalletAddress)
    console.log("tokenPrice", tokenPrice)
    console.log("totalTokens", totalTokens)
    console.log("tokensForRewards", tokensForRewards)
    console.log("associateTokens", associateTokens)
    // return;
    const web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    // Get the current account
    const accounts = await web3.eth.getAccounts();

    const account = accounts[0];
    console.log(account);
    console.log(global.contractAddress);
    let owner = account;
    const cashbackPriceValue = (parseFloat(amount) / etherPrice)
      .toFixed(16)
      .toString();
    let weiValue = 0;
    const cashbackWeiValue = Web3.utils.toWei(cashbackPriceValue.toString(), "ether");
    console.log("cashbackWeiValue1 = ", cashbackWeiValue.toString());
    // console.log("cashbackWeiValue2 = ", "495000000000000000");
    console.log("recipient", recipient);

    let contract = new web3.eth.Contract(ABI, global.contractAddress);
    console.log(contract.methods);
    contract.methods
      .mint(recipient.toString(), tokensForRewards, associateCashbackWalletAddress.toString(), associateTokens)
      .send({
        from: owner,
        gas: 1500000,
        gasPrice: 0,
        value: weiValue
      })
      .then(async function (result) {
        console.log(result)
        const formDataCashback = {
          cashback_list_id: cashback_list_id,
          cashback_list_status: "Approved",
        };
        const res = await updateCashbackList(formDataCashback);
      })
      .then(function (error) {
        console.log(error);
      })
      .catch((error) => console.log(error));
  };

  const addDataAPI = dataAPI?.map((elem) => {
    return {
      cashback_list_price: elem.cashback_list_price,
      user_fullname: elem.user_fullname,
      puchase_plan_wallet_address: elem.puchase_plan_wallet_address,

      action: (
        <div>
          <span>
            <Button
            disabled={elem.cashback_list_status == "Approved"}
              onClick={() => {
                ApproveCashback(elem.cashback_list_id, elem.puchase_plan_wallet_address, elem.cashback_list_price)
              }}
              className="btn btn-success btn-xs"
              style={style2}
            >
              {elem.cashback_list_status == "Requested"
                      ? "Approve" : "Awarded"}
            </Button>
          </span>
        </div>
      ),
    };
  });

  const addColumns = [

    {
      name: "Cashback Amount",
      selector: (row) => row.cashback_list_price,
      sortable: true,
      center: true,
    },
    {
      name: "User Name",
      selector: (row) => row.user_fullname,
      sortable: true,
      center: true,
    },
    {
      name: "Wallet",
      selector: (row) => row.puchase_plan_wallet_address,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs
        parent="Cashback"
        title="Cashback List"
        mainTitle="Cashback"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>{"Cashback List"}</H5>
              </CardHeader>
              <CardBody>
                <div className="table-responsive product-table">
                  <DataTable
                    noHeader
                    pagination
                    paginationServer
                    columns={addColumns}
                    data={addDataAPI}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default CashbacksList;
