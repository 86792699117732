import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs } from "../../AbstractElements";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import Dropzone from "react-dropzone-uploader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
export default function AddSlider() {
    const navigate = useNavigate();
    const [url, seturl] = useState('')
    const [ImageFile, setImageFile] = useState('')
    const [isLoading, setisLoading] = useState(false)

    const handleChangeStatus = ({ meta, file }, status) => {
        setImageFile(file);
    };

    const handleAdd = () => {
        if (!url) {
            return showError("enter the url");
        }
        if (!ImageFile) {
            return showError("Select The Image File");
        }

        function showError(fieldName) {
            toast.error(`Please ${fieldName}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        setisLoading(true)
        const formData = new FormData();
        formData.append("slider_url", url);
        formData.append("sliderImage", ImageFile);

        axios.post(`${global.backendUrl}/slider/addslider`, formData)
            .then((response) => {

                toast.success(`Slider Added Successfully`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setisLoading(false)
                navigate(`${process.env.PUBLIC_URL}/slider/viewSlider`);
            }).catch((err) => {
                console.log(err)
                setisLoading(false)
            })
    }
    return (
        <>
            <Fragment>
                <Breadcrumbs
                    parent="Projects"
                    title="Add Blog"
                    mainTitle="Add Slider"
                />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <Form method="POST">
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>{"Url"}</Label>
                                                    <Input
                                                        type="text"
                                                        required
                                                        value={url}
                                                        className="form-control"
                                                        placeholder="Enter the URL"
                                                        onChange={(e) => seturl(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>{"Slider Image"}</Label>
                                                    <Dropzone
                                                        onChangeStatus={handleChangeStatus}
                                                        inputContent="Drop An Image"
                                                        multiple={false}
                                                        canCancel={true}
                                                        maxFiles={1}
                                                        styles={{
                                                            dropzone: { width: "100%", minHeight: 50 },
                                                            dropzoneActive: { borderColor: "green" },
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup className="mb-0">
                                                    <Button
                                                        onClick={handleAdd}
                                                        className="me-3"
                                                        color="success"
                                                    >
                                                        {
                                                            isLoading ?
                                                                <div class="spinner-border spinner-border-sm" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                                :
                                                                "Submit"
                                                        }
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        </>
    )
}
