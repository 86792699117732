import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs } from "../../AbstractElements";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
// import { insertProjectCategory, updateProjectCategory } from "../../../api/api";
import { useParams, useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import axios from "axios";
import CKEditors from 'react-ckeditor-component';
import Select from 'react-select';
import { TagsInput } from "react-tag-input-component";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const UpdateBlog = () => {
    const { id } = useParams();
    const [data, setdata] = useState([])
    const [isLoading, setisLoading] = useState(false)

    const [title, settitle] = useState("")
    const [category, setcategory] = useState("")
    const [imgFile, setImageFile] = useState("");
    const [description, setdescription] = useState("")
    const [metaTitle, setmetaTitle] = useState("")
    const [metaDesc, setmetaDesc] = useState("")
    const [metaKeywordState, setmetaKeywordState] = useState()
    const [metaTags, setmetaTags] = useState()
    const navigate = useNavigate();

    const handleUpdate = () => {
        if (!title) {
            return showError("enter the title");
        }
        if (!description) {
            return showError("enter the description");
        }
        if (!category) {
            return showError("select the category ");
        }
        if (!metaTitle) {
            return showError("enter the meta title");
        }
        if (!metaDesc) {
            return showError("enter the meta description");
        }
        if (!metaKeywordState) {
            return showError("enter the meta keywords");
        }
        if (!metaTags) {
            return showError("enter the meta tags");
        }

        function showError(fieldName) {
            toast.error(`Please ${fieldName}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        setisLoading(true)
        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", description);
        formData.append("category", category.value);
        formData.append("blogImage", imgFile);
        formData.append("metaTitle", metaTitle);
        formData.append("metaDesc", metaDesc);
        formData.append("metaKeywords", JSON.stringify(metaKeywordState));
        formData.append("metaTags", JSON.stringify(metaTags));

        axios.put(`${global.backendUrl}/blog/update/${id}`, formData)
            .then((response) => {
                setisLoading(false)
                toast.success(`Blog Updated Successfully`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                navigate(`${process.env.PUBLIC_URL}/blogs/blogsList`);
            }).catch((err) => {
                setisLoading(false)
                console.log(err)
            })
    }


    const handleChangeStatus = ({ meta, file }, status) => {
        setImageFile(file);
    };


    const positions = [
        {
            value: 'Top',
            label: 'Top'
        },
        {
            value: 'TopNews',
            label: 'Top News'
        },
        {
            value: 'mostViewsNews',
            label: 'Most Views News'
        },
        {
            value: 'general',
            label: 'General'
        }
    ]

    useEffect(() => {
        axios.get(`${global.backendUrl}/blog/get`)
            .then((response) => {
                console.log("response", response)
                const currentBlog = response.data.filter((t) => t.blog_id == id)
                settitle(currentBlog[0].blog_title)
                setdescription(currentBlog[0].blog_desc)
                setmetaTitle(currentBlog[0].blog_metatitle)
                setmetaDesc(currentBlog[0].blog_metadesc)
                setmetaKeywordState(JSON.parse(currentBlog[0].blog_metakeywords))
                setmetaTags(JSON.parse(currentBlog[0].blog_metatags))

                axios.get(`${global.backendUrl}/blogCategory/get`)
                    .then((response) => {
                        const categories = []
                        response.data.forEach(element => {
                            var value = element.category_name
                            var label = element.category_name
                            categories.push({ value, label })
                        })
                        setdata(categories)
                        console.log("categories", categories)
                        console.log("currentBlog[0].blog_category", currentBlog[0].blog_category)
                        const defaultOption = categories.find(option => option.value === currentBlog[0].blog_category)
                        console.log("defaultOption", defaultOption)
                        setcategory(defaultOption)
                    }).catch((err) => {
                        console.log(err)
                    })
            }).catch((err) => {
                console.log(err)
            })
    }, [])

    function handleChange(value) {
        setdescription(value);
    }
    return (
        <>
            <Fragment>
                <Breadcrumbs
                    parent="Projects"
                    title="Update Blog"
                    mainTitle="Update Blog"
                />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <Form method="POST">
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>{"Title"}</Label>
                                                    <Input
                                                        type="text"
                                                        required
                                                        value={title}
                                                        className="form-control"
                                                        placeholder="Enter the Products Title"
                                                        onChange={(e) => settitle(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>{"Category"}</Label>
                                                    <Select
                                                        options={data}
                                                        value={category}
                                                        onChange={e => setcategory(e)}
                                                        className="js-example-basic-single col-sm-12"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>{"Meta Title"}</Label>
                                                    <Input
                                                        type="text"
                                                        required
                                                        value={metaTitle}
                                                        className="form-control"
                                                        placeholder="Enter the Meta Title"
                                                        onChange={(e) => setmetaTitle(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>{"Meta Description"}</Label>
                                                    <Input
                                                        type="text"
                                                        required
                                                        value={metaDesc}
                                                        className="form-control"
                                                        placeholder="Enter the Meta Description"
                                                        onChange={(e) => setmetaDesc(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>{"Meta Keywords"}</Label>
                                                    <TagsInput
                                                        value={metaKeywordState}
                                                        onChange={setmetaKeywordState}
                                                        placeHolder="Enter Keywords"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>{"Meta Tags"}</Label>
                                                    <TagsInput
                                                        value={metaTags}
                                                        onChange={setmetaTags}
                                                        placeHolder="Enter Keywords"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>{"Description"}</Label>
                                                    {/* <CKEditors
                                                        activeclassName="p10"
                                                        content={description}
                                                        events={{
                                                            'change': e => setdescription(e.editor.getData())
                                                        }}
                                                    /> */}
                                                    <ReactQuill
                                                        value={description}
                                                        onChange={handleChange}
                                                        placeholder="Type something here..."
                                                        modules={{
                                                            toolbar: [
                                                                [{ header: [1, 2, 3, 4, false] }],
                                                                ['bold', 'italic', 'underline', 'strike'],
                                                                [{ color: [] }, { background: [] }],
                                                                [{ align: [] }],
                                                                [{ list: 'ordered' }, { list: 'bullet' }],
                                                                ['link', 'image', 'video'],
                                                                ['clean']
                                                            ]
                                                        }}
                                                        formats={['header', 'bold', 'italic', 'underline', 'strike', 'color', 'background', 'align', 'list', 'bullet', 'link', 'image', 'video']}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>{"Blogs Image"}</Label>
                                                    <Dropzone
                                                        onChangeStatus={handleChangeStatus}
                                                        inputContent="Drop An Image"
                                                        multiple={false}
                                                        canCancel={true}
                                                        maxFiles={1}
                                                        styles={{
                                                            dropzone: { width: "100%", minHeight: 50 },
                                                            dropzoneActive: { borderColor: "green" },
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup className="mb-0">
                                                    <Button
                                                        onClick={handleUpdate}
                                                        className="me-3"
                                                        color="success"
                                                    >
                                                        {
                                                            isLoading ?
                                                                <div class="spinner-border spinner-border-sm" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                                : "Update"
                                                        }
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        </>
    );
};

export default UpdateBlog;
