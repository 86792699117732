import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { insertBadges, updateBadges } from "../../../api/api";

const AddBadge = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [price, setPrice] = useState("");
  const [imgFile, setImageFile] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const handleChangeStatus = ({ meta, file }, status) => {
    setImageFile(file);
  };

  const handleAdd = async () => {
    const formData = new FormData();
    formData.append("badge_name", title);
    formData.append("badge_text", text);
    formData.append("badge_criteria", price);
    formData.append("badge_image", imgFile);

    const res = await insertBadges(formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/badge/badge_list`);
    } else {
      alert("Error");
    }
  };
  const handleUpdate = async (id) => {
    const formData = new FormData();
    formData.append("badge_name", title);
    formData.append("badge_text", text);
    formData.append("badge_criteria", price);
    formData.append("badge_image", imgFile);

    const res = await updateBadges(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/badge/badge_list`);
    } else {
      alert("Error occured.");
    }
  };

  const populateData = () => {
    if (location?.state) {
      setTitle(location?.state?.dataObj?.badge_name);
      setText(location?.state?.dataObj?.badge_text);
      setPrice(location?.state?.dataObj?.badge_criteria);
      setImageFile(location?.state?.dataObj?.badge_image);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  return (
    <Fragment>
      <Breadcrumbs parent="Badge" title="Add Badge" mainTitle="Add Badge" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form method="POST">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Badge Name"}</Label>
                        <Input
                          onChange={(e) => setTitle(e.target.value)}
                          value={title}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Name"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Badge Description"}</Label>
                        <Input
                          onChange={(e) => setText(e.target.value)}
                          value={text}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Description"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Criteria(Votes)*"}</Label>
                        <Input
                          onChange={(e) => setPrice(e.target.value)}
                          value={price}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Votes"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Badge Icon"}</Label>
                        <Dropzone
                          onChangeStatus={handleChangeStatus}
                          inputContent="Drop An Image"
                          multiple={false}
                          canCancel={true}
                          maxFiles={1}
                          styles={{
                            dropzone: { width: "100%", minHeight: 50 },
                            dropzoneActive: { borderColor: "green" },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0">
                        {location?.state ? (
                          <Button
                            onClick={() =>
                              handleUpdate(
                                location?.state?.dataObj?.badge_id
                              )
                            }
                            className="me-3"
                            color="success"
                          >
                            {"Update"}
                          </Button>
                        ) : (
                          <Button
                            onClick={handleAdd}
                            className="me-3"
                            color="success"
                          >
                            {"Submit"}
                          </Button>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddBadge;
